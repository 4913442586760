@import "../../_var.scss";
.ui {
&.dimmer {
  background-color: rgba(0,0,0,.18);
}
&.modal {
  box-shadow: 1px 1px 10px 0px rgba(0, 0, 0 , 0.12), 1px 1px 10px 0px rgba(0, 0, 0, 0.12);
  button{
border-radius: 100px;
&.comnBtn {
    background: transparent linear-gradient(90deg, #44dfd1 1%, #44d3d1 20%, #45b4d2 54%, #4682d3 99%, #4782d4 100%) 0% 0% no-repeat padding-box;
    color: $white;
    }
}
}
}
