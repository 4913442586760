@import "../../../_var.scss";



.accordionSec.ui.accordion {
  margin-top: 30px;
  box-shadow: 0px 0px 20px #0000001c;
  padding: 30px;
  border-radius: 15px;
 

  .wrapper {
    &:last-child {
        .title, .title:not(.ui) {
            border-bottom: none;
        }
    }
    .title,
    .title:not(.ui) {
      position: relative;
      font-size: 18px;
      font-weight: 500;
      padding: 20px 0;
      border-bottom: solid 1px #ddd;
      // text-transform: capitalize;

      @media (max-width: 767px) {
        padding: 15px 0;
        font-size: 16px;
      }

      .icons {
        position: absolute;
        top: 47%;
        right: 0;
        transform: translateY(-50%);
        .icon {
          font-size: 16px;
          transition: all ease 0.4s;
          margin-right: 10px;
          color: $textColor;
          &:last-child {
            margin-right: 0;
          }
          &:hover {
            transition: all ease 0.4s;
            color: $baseColor;
          }
        }
      }
    }

    .content {
      font-size: 16px;
      font-weight: 400;
      padding: 0 0 20px 28px !important;
      color: $lgtTextColor;
      // text-transform: capitalize;
      @media (max-width: 767px) {
        padding: 0 0 15px 28px !important;
        font-size: 15px;
      }
    }
  }
}
