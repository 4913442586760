@import '../../_var.scss';

.bm-burger-bars {
    background: #fff !important;
    height: 2px !important;
    margin-bottom: 6px !important;
}

main {
    height: 100%;
    padding: 0em 0;
    text-align: center;
    background:$boxBg;
    overflow: auto;
  }
  h1 {
    font-weight: 800;
    font-size: 3.75em;
  }
 
  .side-button {
    display: inline-block;
    width: 5em;
    height: 2.5em;
    line-height: 2.5em;
    cursor: pointer;
    background: #fffce1;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 0.8em;
    font-weight: 800;
  }
  .side-button.left {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  .side-button.right {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  .side-button.active {
    background: #c94e50;
    color: #fffce1;
  }

  .bm-burger-button {
    position: absolute;
    width: 30px;
    height: 24px;
    right: 40px;
    top: 40px;
   }
  .bm-burger-button button:focus {
    outline: 2px solid #c94e50;
    outline-offset: 8px;
  }
  .bm-burger-button button:focus + span span.bm-burger-bars {
    background-color: #c94e50;
  }
  .right .bm-burger-button {
    left: initial;
    right: 36px;
  }
  .bm-morph-shape {
    fill: #373a47;
  }
  .bm-menu {
    background: #373a47;
  }
  .bm-menu a {
    color: #b8b7ad;
  }
  .bm-menu a:hover,
  .bm-menu a:focus {
    color: #c94e50;
  }
  .bm-item-list a {
    padding: 0.8em;
    display: block;
    text-align: left;
    font-size: 16px;
    border-bottom: 1px solid #fff;
    padding: 20px 10px;
    color: #fff;
    &:hover{
        color: #2197e5;
    }
    i{
      padding-right: 10px;
    }
    .top-icons{
      font-size: 22px;
      color: #6c668e;
      margin-left: 0;
      width: 75px;
      display: flex;
      justify-content: space-between;
      .icon{
        &:hover{
          color: #fff !important;
        }
      }
    }
}
  .bm-item-list a span {
    margin-left: 35px;
    font-weight: 500;
  }
  .bm-item:focus {
    outline: none;
  }

  .bm-menu-wrap {
    width: 100% !important;
    right: 0!important;
    top:0;
}

a#contact {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 10px 15px 15px!important;
}
ul.bottom-menu-list {
  padding: 0 10px;
}

.bm-cross-button {
  right: 20px !important;
  top: 20px !important;
  z-index: 222;
}
span.bm-cross {
    background: #fff;
    width: 2px!important;
    height: 24px!important;
}