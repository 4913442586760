.usersList {
  button {
    min-width: 100px;
    &.BtnDisabled {
      background-color: #101010;
      color: #ffffff;
      &:hover,
      &:active,
      &:focus {
        background-color: #101010;
        color: #ffffff;
      }
    }
  }
  .icon {
    opacity: 0.8;
    &:hover {
      opacity: 1;
    }
  }
}
