$mainBg: #1b161d;
$boxBg: #eeeeee;
$baseColor: #519bf4;
$borderColor: #23aaff;
$mainText: #3ec9ef;
$white: #ffffff;
$blackBg: #000;
$textColor: #3f3f3f;
$lgtTextColor: #888888;
$gradient: linear-gradient(to right, #41cff0, #1c8ee3);
$gradientHover: linear-gradient(to right, #1c8ee3, #41cff0);
