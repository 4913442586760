@import "../../_var.scss";
.leftBar {
  width: 100%;
  min-width: 251px;
  max-width: 251px;
  z-index: 10;
  background: $white;
  box-shadow: 0px 0px 14px #0000003b;
  transition: all 0.35s;
  overflow-y: auto;
  position: fixed;
  height: 100%;
  left: 0px;

  @media (max-width: 991px) {
    position: relative;
    min-width: auto !important;
    max-width: 100% !important;
    width: calc(100% - 30px) !important;
    margin-top: 20px !important;
    &.isShow {
      left: 0;
    }
  }
  h2.navHeading {
    display: none;
    padding: 16px 0 0 48px;
    font-size: 12px;
    text-transform: uppercase;
    color: #abafb3;
    box-sizing: border-box;
    @media (max-width: 1366px) {
      padding-left: 19px;
    }
  }

  .sidebarNav {
    padding: 0 15px;
    margin-bottom: 28px;

    ul {
      margin: 0px;
      padding: 0px;
      list-style: none;
      li {
        border-bottom: 1px solid #ddd;
        &:last-child {
          border-bottom: none;
        }
        a {
          font-size: 18px;
          color: $textColor;

          font-weight: 400;
          font-style: normal;
          padding-left: 20px;
          padding-top: 16px;
          padding-bottom: 18px;
          margin-bottom: 0;
          display: block;
          transition: all 0.25s;

          &:hover,
          &.active {
            color: $baseColor;
          }
        }
      }
    }

    > h4 {
      font-size: 18px;
      color: #39cbf6;
      font-weight: 400 !important;
      text-align: center;
      font-style: normal;
      text-align: center;
      margin-bottom: 23px;
      margin-top: 0;
      width: 100%;
      @media (max-width: 991px) {
        margin-bottom: 10px;
        margin-top: 0px;
      }
    }
    > label {
      background: #2a384a;
      width: 100%;
      padding: 15px 0;
      border-radius: 8px;
      color: $textColor;
      font-size: 18px;
      font-weight: 500;
      margin-bottom: 40px;
      text-align: center;
      display: block;
      @media (max-width: 991px) {
        margin-bottom: 20px;
      }
    }
  }
  .sn_identity {
    @media (max-width: 991px) {
      display: flex;
      justify-content: space-between;
    }
    @media (max-width: 767px) {
      flex-wrap: wrap;
    }
    @media (max-width: 600px) {
      flex-direction: column;
    }
    .total_earn {
      background: #17212f;
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      margin-bottom: 20px;
      padding: 15px 0 18px 0;
      &.last {
        padding-bottom: 0 !important;
        @media (max-width: 767px) {
          width: 100% !important;
        }
      }
      .invest_btn {
        width: 100%;
        font-size: 16px;
        color: $textColor;
        text-transform: uppercase;
        font-weight: 400;
        background-image: $gradient;
        border: 0px;
        margin-top: 20px;
        border-radius: 0 0 5px 5px;
        padding: 15px 0;
        cursor: pointer;
        &:hover {
          background: linear-gradient(to right, #1c8ee3, #41cff0);
        }
      }
      @media (max-width: 991px) {
        width: 18%;
        text-align: center;
      }
      @media (max-width: 767px) {
        width: 48%;
      }
      @media (max-width: 600px) {
        width: 100%;
      }
      label {
        font-size: 30px;
        line-height: 35px;
        color: $textColor;
        font-weight: 300;
        margin-bottom: 0;
        @media (max-width: 1250px) {
          font-size: 25px;
        }
      }
      span {
        font-size: 16px;
        color: $mainText;
        @media (max-width: 1250px) {
          font-size: 14px;
        }
      }
    }

    @media (max-width: 1366px) {
      margin-bottom: 10px;
    }
  }
}

.sn_matTypeTop {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px;
  p {
    color: #fff;
    font-weight: 500;
    font-size: 14px;
    line-height: 10px;
    margin: 8px 0 !important;
  }
}
.left_logoBlock {
  margin-top: 22px;
  width: 100%;
  height: 140px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 30px auto;
  img {
    max-width: 100%;
  }
  @media (max-width: 1366px) {
    padding: 0 10px;
  }
  @media (max-width: 980px) {
    margin-bottom: 10px;
  }
}

.sn_LinkBar {
  margin-bottom: 30px;
  &.LinkBar {
    padding-top: 20px;
    @media (max-width: 991px) {
      padding-top: 0;
    }
  }
  label {
    font-size: 18px;
    color: $textColor;
    font-weight: 300;
    display: block;
    margin-bottom: 10px;
    @media (max-width: 991px) {
      font-size: 16px;
    }
  }
  .linkBlock {
    display: flex;
    align-items: center;
    border-radius: 10px;
    padding: 10px;
    background: #17212f;
    span {
      font-size: 12px;
      color: $textColor;
      font-weight: 400;
      overflow: hidden;
      flex: 1;
      white-space: nowrap;
    }
    a {
      margin-left: 10px;
      img {
        width: 12px;
        margin-top: 4px;
      }
    }
  }
}
@media (max-width: 991px) {
  .mainBlock .leftBar .left_logoBlock {
    text-align: center;
    background-color: transparent;
  }
  .mainBlock .leftBar .sidebarNav {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .mainBlock .leftBar .sn_identity {
    width: 100%;
    margin-right: 0px;
    margin-bottom: 0px;
  }
  .sn_LinkBar,
  .sn_matType {
    width: 32%;
    margin-bottom: 0px;
  }
  .sn_matTypeTop {
    width: 100%;
    margin-bottom: 30px;
  }
  .sn_LinkBar a.ethScanLink {
    font-size: 21px;
  }
  .sn_matType ul li {
    flex-direction: row;
  }
  .sn_matType h2 {
    width: 72px;
    height: 72px;
    border-radius: 5px;
    font-size: 33px;
    margin-right: 14px;
  }
  .sn_matType ul li strong {
    font-size: 25px;
    line-height: 28px;
  }
  .mainBlock .leftBar .sn_identity ul li {
    flex-direction: row;
  }
  .sn_matType ul li span {
    font-size: 25px;
    line-height: 28px;
    padding-left: 5px;
  }
}
@media (max-width: 767px) {
  .sn_LinkBar {
    width: 100%;
    margin-bottom: 0px;
  }
  .sn_LinkBar.LinkBar {
    margin-bottom: 20px;
  }
  .sn_matType {
    width: 100%;
    margin-bottom: 20px;
  }
  .mainBlock .leftBar .sn_identity {
    margin-bottom: 10px;
  }
}
.dropbtn {
  background-color: #3498db;
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
  cursor: pointer;
}

.dropbtn:hover,
.dropbtn:focus {
  background-color: #2980b9;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  overflow: auto;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown a:hover {
  background-color: #ddd;
}

.show {
  display: block;
}

ul li ul.dropdown {
  min-width: 100%; /* Set width of the dropdown */
  background: #f2f2f2;
  display: none;
  position: absolute;
  z-index: 999;
  left: 0;
}
ul li:hover ul.dropdown {
  display: block; /* Display the dropdown */
}
ul li ul.dropdown li {
  display: block;
}
