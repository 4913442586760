.testimonial-page {
  .headSec {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-bottom: 50px;
  }
  .hrline {
    float: left;
    border: 1px solid;
    border-top: 0;
    border-bottom: 0;
    border-left: 0;
    border-image: linear-gradient(
      to bottom,
      rgba(90, 84, 234, 1) 0%,
      rgba(1, 169, 242, 1) 48%,
      rgba(90, 84, 234, 1) 100%
    );
    border-image-slice: 1;
  }
  .eight.wide.column {
    h5 {
      color: #6380fe;
    }
    .checkImage {
      max-width: 200px;
      width: 100%;
      height: 150px;
      border-radius: 10px;
      margin-right: 30px;
    }
    .checkinputSec {
      display: flex;
      align-items: start;
      background-color: #8080801f;
      border-radius: 20px;
      margin-bottom: 20px;

      h6 {
        margin: 0;
      }
      padding: 20px;
      p {
        min-height: 50px;
        overflow: auto;
      }
    }
    input#vehicle1 {
      width: 30px;
    }
    input[type="checkbox"] {
      accent-color: #6380fe;
      &:hover {
        accent-color: #44d3d1;
      }
    }
  }
}
input#myFile {
  border: none !important;
  margin-top: 20px;
}

i.close.icon {
  float: right;
}
.image.content {
  display: flex;
  .description {
    .form-input {
      padding: 0 !important;
    }
  }
}
