
    
.logoBlock{
    text-align: center;
    margin:0px;
    padding: 0;
    width: 250px;
    img{
        max-width: 100%;
     }
     @media(max-width: 1366px){
         padding: 0 10px;
     }
}

.header_crruntActivityRow{
    display: flex;
    border-top: 1px solid rgba(242, 244, 255, 0.14);
    background: #333;
    padding: 0px 25px;
    margin: 0;
    li{
        display: flex;
        flex-direction: column;
        text-align: center;
        padding: 20px 65px ;
        font-size: 12px;
        min-width: 120px;
        strong{
            color: #888;
        }
        span{
            color: #f2f4ff;
        }
    }
}
   
   
 