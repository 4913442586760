.collectionItems {
  padding-left: 0;
  list-style: none;
  padding: 30px;
  box-shadow: 0px 0px 14px #0000003b;
  border-radius: 20px;
  li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    border-bottom: solid 1px #eee;
    font-size: 15px;
    &:last-child {
      border-bottom: none;
    }
    label {
      font-weight: 600;
    }
    p {
      font-size: 16px;
    }
  }
}
