@import "./_var.scss";

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.loginBoxMain {
  width: 800px !important;
  padding: 50px 30px;
  box-shadow: 0px 0px 5px $textColor;
  border-radius: 15px;
  background: #fff;
}
.loginBoxMain h3 {
  margin-bottom: 40px !important;
  color: #000;
  font-size: 30px;
  text-align: center;
}
#title {
  text-align: center;
  font-family: arial, sans-serif;
}

.overlayDiv {
  position: fixed;
  background: rgba(0, 0, 0, 0.2);
  width: 100%;
  height: 100%;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
}
.overlayDiv div {
  position: relative;
}
.form__field-error {
  color: red;
  font-size: 16px;
  margin-top: 5px;
}
.centerBox {
  margin-bottom: 10px !important;
  margin-top: 10px !important;
}
.IncomeTable {
  h2 {
    font-size: 22px;
    color: #000000 !important;
    font-weight: 700;
  }
  table {
    border: 0px !important;
    border-radius: 12px !important;
    background: transparent !important;
    thead {
      tr {
        th {
          color: #566274 !important;
          font-size: 16px !important;
          font-weight: 600 !important;
          height: 65px !important;
          text-align: center !important;
          border-bottom: 0 solid #000 !important;
          text-align: left !important;
          padding: 0 30px !important;
          color: $textColor !important;
          background: #000000 !important;
          &:first-child {
            border-radius: 15px 0 0 0 !important;
          }
          &:last-child {
            border-radius: 0 15px 0 0 !important;
          }
          @media (max-width: 1250px) {
            font-size: 14px !important;
            padding: 0 20px !important;
          }
          @media (max-width: 991px) {
            padding: 0 15px !important;
            font-weight: 600 !important;
            height: 55px !important ;
          }
        }
      }
    }
    tbody {
      padding: 20px 0;
      tr {
        td {
          color: $textColor !important;
          font-size: 15px !important;
          font-weight: 400 !important;
          height: 50px !important;
          background: $boxBg !important;
          border-top: 0px solid #364458 !important;
          border-bottom: 0 !important;
          padding: 0 30px !important;
          text-align: left !important;
          @media (max-width: 1250px) {
            font-size: 13px !important;
            padding: 0 20px !important;
          }
          @media (max-width: 991px) {
            padding: 0 15px !important;
            height: 40px !important;
            white-space: nowrap !important;
          }
          img {
            margin-right: 5px;
          }
          &.bouts {
            color: #3fccef !important;
          }
          &.act {
            color: #00a900 !important;
          }
          &.inactive {
            color: #ff0000 !important;
          }
        }

        &:last-child {
          td {
            &:first-child {
              border-radius: 0 0 0 15px !important;
            }
            &:last-child {
              border-radius: 0 0 15px 0 !important;
            }
          }
        }
      }
    }
    tfoot {
      tr {
        background: transparent !important;
        border-bottom: 0px solid #000;
        td {
          background: transparent !important;
          font-size: 20px;
          font-weight: 700;
          border-top: 0px solid #000;
          height: 35px !important;
          .total {
            color: #f67200 !important;
          }
          a {
            background: #d73852;
            font-size: 12px;
            font-weight: 700;
            color: $textColor;
            text-align: center;
            border-radius: 5px;
            display: block;
            margin: 0 auto;
            width: 160px;
            padding: 8px 0;
            margin-top: -20px;
            @media (max-width: 980px) {
              font-size: 18px !important;
              padding: 12px 0;
            }
          }
        }
      }
    }
  }
}

.nav-item.nav-link {
  color: $textColor !important;
  font-size: 15px !important;
  border: 0px !important;
}

.nav-item.nav-link:focus,
.nav-item.nav-link:hover {
  color: $textColor !important;
  background: linear-gradient(to right, #937a34, #cab069);
}

.nav-item.nav-link.active {
  background: linear-gradient(to right, #937a34, #cab069);
  color: $textColor !important;
}

.nav-item.nav-link:hover {
  font-size: 16px;
  font-weight: 500 !important;
  border: none !important;
  background-color: #c4a910;
  outline: none;
}
.ui.pagination.menu {
  margin-bottom: 20px;
}
.IncomeTable {
  .ui.pagination.menu {
    border-color: #000 !important;
    background: #000 !important;
    a {
      background: #101010;
      color: #fff;
      &.active {
        background: linear-gradient(to right, #1c8ee3, #41cff0) !important;
        color: #fff !important;
      }
      &:hover {
        background: linear-gradient(to right, #1c8ee3, #41cff0) !important;
        color: #fff;
      }
    }
  }
}
.create-nft-form {
  padding-top: 25px;

  .field {
    margin-bottom: 20px !important ;
  }
}

td {
  cursor: pointer;
}

.mainBlock {
  .mr-10 {
    margin-right: 10px;
  }
  .mb-15 {
    margin-bottom: 15px;
  }
  .ms-auto {
    margin-left: auto;
  }
  .float-rgt {
    float: right;
  }
  .ui.button,
  .comnBtn {
    cursor: pointer;
    background: transparent
      linear-gradient(
        90deg,
        #44dfd1 1%,
        #44d3d1 20%,
        #45b4d2 54%,
        #4682d3 99%,
        #4782d4 100%
      )
      0% 0% no-repeat padding-box;
    box-shadow: 4px 4px 7px #00000030;
    border-radius: 22px;
    white-space: nowrap;
    font-size: 15px;
    color: $white;
    text-transform: uppercase;
    font-weight: 500;
    padding: 12px 18px;
    min-width: 107px;
    border: none;
  }
}
iframe {
  display: none;
}
.mb-20 {
  margin-bottom: 20px;
}
.mt-20 {
  margin-top: 20px;
}
input,
select {
  font-size: 16px;
  color: $textColor;
  background: transparent;
  text-align: center;
  border-radius: 5px;
  height: 52px;
  border: 1px solid $borderColor !important;
  &:focus,
  &:hover,
  &::placeholder {
    color: $textColor;
  }
}
.table-responsive {
  overflow: auto;
  width: 100%;
}
