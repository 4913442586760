@import "../../_var.scss";

.mainBlock {
  position: relative;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: stretch;
  align-content: stretch;
  box-sizing: border-box;
  padding-left: 251px;

  &:before {
    position: fixed;
    content: "";
    width: 100%;
    height: 1px;
    display: none;
    top: 0px;
    left: 0px;
    background-color: #ff0000;
    z-index: 11;
  }
}

.headerTop {
  display: flex;
  justify-content: space-between;
  padding: 21px 39px 14px;
  min-height: 95px;
  align-items: center;
  box-sizing: border-box;
  z-index: 1;
  background: $white;
  box-shadow: 0px 0px 14px #0000003b;
  z-index: 8;
  margin-bottom: 0px;
  padding-left: 290px;
  @media (max-width: 1300px) {
    padding: 45px 25px 14px;
    padding-left: 260px;
  }
  @media (max-width: 1106px) {
    flex-direction: column-reverse;
    height: 90px;
  }
  &:before {
    position: absolute;
    content: "";
    width: calc(100% - 70px);
    left: 36px;
    height: 1px;
    background: #1e223e;
    bottom: 0;
    display: none;
  }
  // @media(max-width:1366px){
  //     width: calc(100% - 221px);
  // }
  @media (max-width: 824px) {
    background: #2e77bc;
  }
  @media (max-width: 812px) {
    width: 100%;
    padding: 15px;
    padding-left: 40px;
  }
  .headerRight {
    font-size: 18px;
    a {
      color: #000;
    }
  }
}
.sn_matType {
  border-style: solid;
  border-image-slice: 1;
  border-image-source: linear-gradient(to left, #ff0000, #2094fa);
  border-radius: 10px;
  padding: 5px;
  display: flex;
  padding-right: 10px;
  margin-bottom: 10px;
  align-items: center;
  &.ex_bottom_margin {
    margin-bottom: 29px;

    @media (max-width: 1366px) {
      margin-bottom: 15px;
    }
    @media (max-width: 991px) {
      margin-bottom: 30px;
    }
  }
  @media (max-width: 1366px) {
    padding-right: 17px;
    border-radius: 5px;
    margin-bottom: 5px;
  }
  h2 {
    width: 60px;
    height: 60px;
    border-radius: 10px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#dbdfe7+0,989da3+100 */
    background: #bf0000;
    font-size: 18px;
    font-weight: 600;
    color: $textColor;
    margin: 0;
    padding: 0;
    margin-right: 5px;

    @media (max-width: 1366px) {
      width: 40px;
      height: 40px;
      border-radius: 5px;
      font-size: 15px;
      margin-right: 14px;
    }
  }
  ul {
    display: flex;
    flex: 1;
    justify-content: space-between;
    margin: 0;
    padding: 0;
    list-style: none;
    @media (max-width: 991px) {
      flex-direction: column;
    }
    li {
      display: flex;
      flex-direction: column;
      min-width: 84px;
      @media (max-width: 1366px) {
        min-width: 62px;
      }
      @media (max-width: 991px) {
        margin-bottom: 4px;
        &:last-child() {
          margin-bottom: 0px;
        }
      }
      strong {
        font-size: 16px;
        font-weight: 500;
        color: $textColor;
        @media (max-width: 1366px) {
          font-size: 12px;
        }
      }
      span {
        font-size: 14px;
        font-weight: 700;
        color: #afb3ba;
        @media (max-width: 1366px) {
          font-size: 10px;
        }
      }
    }
  }
}

.headerRight {
  width: 100%;
  display: flex;
  justify-content: space-between;
  .navRight {
    margin: 0;
    padding: 0;
    list-style: none;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    &.dash {
      justify-content: start;
    }
    @media (max-width: 1106px) {
      margin-bottom: 8px;
    }
    li.navR_item {
      margin-left: 15px;
      @media (max-width: 1106px) {
        margin-left: 8px;
        margin-right: 8px;
      }
      a {
        background: transparent
          linear-gradient(
            90deg,
            #44dfd1 1%,
            #44d3d1 20%,
            #45b4d2 54%,
            #4682d3 99%,
            #4782d4 100%
          )
          0% 0% no-repeat padding-box;
        box-shadow: 4px 4px 7px #00000030;
        border-radius: 22px;
        white-space: nowrap;
        position: relative;
        font-size: 15px;
        color: $white;
        transition: all 0.25s;
        text-transform: uppercase;
        font-weight: 400;
        padding: 12px 18px;
        text-decoration: none;
      }
    }
  }
}
.rightSection {
  position: relative;
  padding: 0;
  width: 100%;
  flex: 1;
  box-sizing: border-box;

  @media (max-width: 812px) {
    padding-top: 83px;
    &.isShow {
      &::before {
        position: absolute;
        content: "";
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background: rgba(0, 0, 0, 0.5);
        z-index: 1;
      }
    }
  }
}

.contentArea {
  box-sizing: border-box;
  padding: 0 0 0 20px;
  h2 {
    color: $textColor;
  }
  @media (max-width: 991px) {
    padding: 0px;
  }
}

.teamMain {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: stretch;
  align-content: stretch;
  height: calc(100% - 75px);
  .teamRight {
    width: 100%;
    box-sizing: border-box;
    padding-top: 32px;
    @media (max-width: 991px) {
      padding-top: 20px;
    }
    .list_of_info {
      margin: 0;
      padding: 0;
      display: flex;
      justify-content: space-between;
      padding-bottom: 15px;
      @media (max-width: 1120px) {
        flex-wrap: wrap;
      }
      li {
        width: 23%;
        margin-left: 1%;
        margin-right: 1%;
        background: $boxBg;
        padding: 15px 40px;
        border-radius: 5px;
        list-style: none;
        text-align: center;
        @media (max-width: 1120px) {
          margin-bottom: 10px;
        }
        @media (max-width: 767px) {
          width: 48%;
        }
        @media (max-width: 550px) {
          width: 100%;
          margin: 0 0 10px 0;
        }
        label {
          font-size: 30px;
          font-weight: 300;
          color: $mainText;
          line-height: 34px;
          @media (max-width: 1366px) {
            font-size: 22px;
            line-height: 30px;
          }
          @media (max-width: 991px) {
            font-size: 18px;
            line-height: 24px;
          }
        }
        span {
          font-size: 15px;
          color: #566274;
          font-weight: 400;
          display: block;
        }
        &:first-child {
          margin-left: 0;
        }
        &:last-child {
          margin-right: 0;
        }
      }
    }
    .list_of_plans {
      margin: 25px 0;
      padding: 0;
      display: flex;
      justify-content: space-between;
      @media (max-width: 1120px) {
        flex-wrap: wrap;
        margin-top: 10px !important;
        margin-bottom: 0 !important;
      }
      li {
        width: 23%;
        margin-left: 1%;
        margin-right: 1%;
        background: $boxBg;
        padding: 15px 0px 0 0px;
        border-radius: 5px;
        position: relative;
        list-style: none;
        @media (max-width: 1120px) {
          width: 47% !important;
          margin-bottom: 30px;
        }
        @media (max-width: 600px) {
          width: 100% !important;
          margin-bottom: 30px;
        }
        &:before {
          height: 3px;
          width: 100%;
          background: $gradient;
          content: "";
          position: absolute;
          border-radius: 5px 5px 0 0;
          position: absolute;
          top: 0;
          left: 0;
        }
        &:first-child {
          margin-left: 0;
          @media (max-width: 1120px) {
            margin-left: 1%;
          }
        }
        &:last-child {
          margin-right: 0;
          @media (max-width: 1120px) {
            margin-right: 1%;
          }
        }
        h2 {
          font-size: 30px;
          text-align: center;
          text-transform: uppercase;
          font-style: normal;
          font-weight: 600;
          margin: 15px 0 35px 0;
          background: -webkit-linear-gradient(45deg, #41cff0, #1c8ee3);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          @media (max-width: 1500px) {
            font-size: 25px;
          }
          @media (max-width: 1300px) {
            font-size: 20px;
          }
          span {
            font-size: 18px;
            background: -webkit-linear-gradient(45deg, #fff, #fff);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            display: block;
            font-style: 400;
            line-height: 20px;
            @media (max-width: 1300px) {
              font-size: 14px;
            }
          }
        }
        h3 {
          width: 185px;
          height: 185px;
          border-radius: 50%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          margin: 0 auto;
          background: #1b161d;
          @media (max-width: 1300px) {
            width: 150px;
            height: 150px;
          }
          .per {
            font-weight: 700;
            font-style: normal;
            color: $textColor !important;
            font-size: 60px !important;
            line-height: 45px;
            @media (max-width: 1500px) {
              font-size: 45px !important;
            }
            @media (max-width: 1300px) {
              font-size: 30px !important;
            }
            b {
              color: $textColor;
              font-weight: 400;
              font-size: 30px;
            }
          }
          span {
            font-size: 16px;
            display: block;
            color: #566274;
            line-height: 18px;
            font-style: normal;
          }
        }
        .roiList {
          width: 100%;
          display: inline-block;
          margin: 0 auto;
          padding: 42px 0 5px 0;
          li {
            margin: 0;
            padding: 0 0 15px 0;
            float: left;
            text-align: center;
            width: 100% !important;
            font-size: 16px;
            font-weight: 500;
            display: block;
            color: $textColor;
            position: relative;
            @media (max-width: 1500px) {
              font-size: 15px;
            }
            @media (max-width: 1300px) {
              font-size: 14px;
              padding-bottom: 10px;
            }
            @media (max-width: 1250px) {
              font-size: 12px;
            }
            @media (max-width: 1120px) {
              font-size: 14px;
            }

            &::before {
              display: none !important;
            }
            span {
              color: $mainText;
            }
          }
        }
        label {
          display: block;
          text-align: center;
          font-display: 14px;
          color: #566274;
          font-weight: 400;
          margin-top: 15px;
        }
      }
    }
    .invest_btn {
      width: 100%;
      font-size: 18px;
      color: $textColor;
      text-transform: uppercase;
      font-weight: 400;
      background-image: $gradient;
      border: 0px;
      margin-top: 20px;
      border-radius: 0 0 5px 5px;
      padding: 20px 0;
      cursor: pointer;
      &:hover {
        background: linear-gradient(to right, #1c8ee3, #41cff0);
      }
    }
    .input_main {
      width: 100%;
      padding: 15px;

      @media (max-width: 1120px) {
        width: 263px;
        margin: 0 auto;
        padding: 0 !important;
      }
      .input {
        width: 100%;
      }
      input {
        background: #18141a;
        font-size: 16px;
        text-align: center;
        height: 40px;
        color: $textColor;
        border: 1px solid #1b181e;
        width: 65%;
        padding: 5px !important;
        &::placeholder {
          color: $textColor;
        }
      }
      .label {
        font-size: 16px;
        height: 40px;
        line-height: 10px;
        color: $textColor;
        background: $mainText;
        width: 35%;
        text-align: center !important;
        border: 1px solid $mainText;
        padding-top: 14px !important;
        @media (max-width: 1300px) {
          font-size: 12px;
        }
      }
    }
    .investments {
      width: 100%;
      background: #1e2938;
      border-radius: 5px;
      padding: 28px 42px 22px 42px;
      h2 {
        font-size: 16px;
        color: #c5ab64;
        font-weight: 500;
        font-style: normal;
        margin-bottom: 45px;
      }
    }
    .Dividend_full {
      display: flex;
      width: 100%;
      margin-bottom: 30px;
      justify-content: space-between;
      @media (max-width: 1120px) {
        flex-direction: column;
        margin-bottom: 0;
      }
      .half {
        width: 49%;
        background: #1e2938;
        border-radius: 5px;
        padding: 28px 42px 22px 42px;
        @media (max-width: 1120px) {
          width: 100%;
          margin-bottom: 30px;
        }
        .LinkBar {
          margin-bottom: 45px;
          label {
            font-size: 14px;
            color: #566274;
            font-weight: 300;
            display: block;
            margin-top: 8px;
            margin-bottom: 8px;
          }
          .linkBlock {
            display: flex;
            align-items: center;
            border-radius: 10px;
            padding: 10px;
            background: #17212f;
            span {
              font-size: 14px;
              color: $textColor;
              font-weight: 400;
              overflow: hidden;
              flex: 1;
              white-space: nowrap;
            }
            a {
              margin-left: 10px;
              img {
                width: 12px;
                margin-top: 4px;
              }
            }
          }
        }
        h2 {
          font-size: 16px;
          color: #c5ab64;
          font-weight: 500;
          font-style: normal;
          margin-bottom: 45px;
        }
        .Withdraw_btn {
          font-size: 18px;
          color: $textColor;
          text-transform: uppercase;
          font-weight: 400;
          padding: 17px 0;
          width: 295px;
          border-radius: 5px;
          border: 0px;
          margin: 3px 0 12px 0;
          background: linear-gradient(to right, #937a34, #cab069);
        }
        label {
          font-size: 14px;
          color: #566274;
          font-weight: 300;
          display: block;
        }
        ul {
          margin: 0;
          padding: 0;
          li {
            list-style: none;
            font-size: 14px;
            color: #566274;
            font-weight: 300;
            margin: 0;
            line-height: 24px;
            padding: 0;
            width: 50%;
            float: left;
            margin-bottom: 30px;
            span {
              color: $textColor;
              font-size: 24px;
            }
          }
        }
      }
    }
  }
}

.subHeader {
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 11px 20px;
  align-items: center;
  @media (max-width: 812px) {
    background: #000;
  }
}
.menuList {
  display: flex;
  list-style: none;
  margin: 0px;
  padding: 0px;
  @media (max-width: 540px) {
    flex-wrap: wrap;
  }
}

.menuList li {
  margin-right: 10px;

  @media (max-width: 540px) {
    &:nth-child(1) {
      margin-right: 20px;
    }
    &:nth-child(3) {
      background: rgba(255, 255, 255, 0.2);
      margin-top: 10px;
      padding: 5px;
      width: 100%;
    }
  }
  select {
    background: transparent;
    color: $textColor;
    border: 0;
    margin-right: 10px;
    option {
      color: #000d19;
      font-size: 11px;
    }
  }
}

.menuList li a,
.menuList li button {
  color: $textColor;
  font-weight: 600;
  padding: 5px 15px;
  transition: all 0.25s;
  background: transparent;
  border: 0px;

  @media (max-width: 1279px) {
    font-size: 14px;
  }
  @media (max-width: 1279px) {
    font-size: 14px;
    padding: 0px;
  }
}
.menuList li button {
  padding: 0;
  cursor: pointer;
  font-weight: 400;
}
.menuList li a:hover,
.menuList li button:hover {
  background: rgba(255, 255, 255, 0.1);
  background: transparent;
  border: 0px;
}
.searchBlock {
  width: 205px;
  position: relative;
  display: none;
}
.searchBlock input[type="text"] {
  width: 100%;
  height: 35px;
  border: 0px;
  padding: 0 10px 0 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  background: transparent;
  color: $textColor;
  outline: none;
}
.searchBlock input[type="text"]::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: $textColor;
  opacity: 1; /* Firefox */
}

.searchBlock input[type="text"]:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: $textColor;
}

.searchBlock input[type="text"]::-ms-input-placeholder {
  /* Microsoft Edge */
  color: $textColor;
}
.searchBlock button {
  position: absolute;
  right: 0px;
  top: 5px;
  background: transparent;
  border: 0px;
}
.searchBlock button span {
  background: url(../../images/search_img.png) right center no-repeat;
  width: 25px;
  height: 25px;
  font-size: 0px;
  display: block;
}

.teamHeader {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  padding-top: 35px;

  h3 {
    font-size: 36px;
    font-weight: 600;
    color: #282828;
    margin: 10px 0 0 0;
  }
  &.notTeam {
    justify-content: center;
    justify-content: flex-start;
    flex-direction: row;
    align-items: center;
    margin-bottom: 24px;
    img {
      margin-right: 20px;
      @media (max-width: 1279px) {
        width: 50px;
        margin-right: 10px;
      }
      @media (max-width: 812px) {
        width: 28px;
        margin-right: 10px;
      }
    }

    h3 {
      text-align: left;
      @media (max-width: 812px) {
        font-size: 17px;
        margin-bottom: 14px;
      }
    }
    @media (max-width: 812px) {
      padding-top: 3px;
      margin-bottom: 0;
    }
  }
}

.TransHeader {
  padding-top: 0 !important;
  img {
    width: 60px;
  }
  h3 {
    font-size: 24px !important;
  }
}

ul.about_platForm {
  display: flex;
  color: $textColor;
  list-style: none;
  margin: -5px 0 20px 0;
  padding: 0;
  align-items: center;
  justify-content: center;
}
ul.about_platForm li {
  font-size: 16px;
  color: #b9b9bb;
  margin-right: 20px;
  display: flex;
  align-items: center;
  font-weight: 400;

  @media (max-width: 1366px) {
    font-size: 12px;
  }

  img {
    margin-right: 11px;
  }
  &:nth-child(1) {
    img {
      width: 10px;
    }
  }
  &:nth-child(2) {
    img {
      width: 12px;
    }
  }
  &:nth-child(3) {
    img {
      width: 16px;
    }
  }
}
.ui.table.tableTransitionList {
  background: transparent;
  color: $textColor;
  padding: 20px 0 40px 0 !important;
  @media (max-width: 1500px) {
    padding-bottom: 40px;
  }
}
.ui.table.tableTransitionList.tableTransitionBox {
  padding: 0px 0 20px 0 !important;
}
.ui.table.tableTransitionList th {
  font-size: 25px;
  color: #bf0000;
  font-weight: 700;
  border-bottom: 1px solid rgba(122, 125, 131, 0.36);
  background: transparent;
  white-space: nowrap;

  @media (max-width: 1366px) {
    font-size: 18px;
  }
}
.ui.table.tableTransitionList td {
  font-size: 22px;
  color: $textColor;
  font-weight: 700;
  border-bottom: 1px solid rgba(122, 125, 131, 0.36);
  @media (max-width: 1366px) {
    font-size: 16px;
  }
}

.arrowUp {
  background: grey;
  border-radius: 50%;
  width: 25px;
}
.arrowDown {
  background: grey;
  border-radius: 50%;
  width: 25px;
}
@media (max-width: 991px) {
  .rightSection {
    padding-top: 0;
    position: static;
    z-index: 100;
    .headerTop {
      width: 100%;
      position: absolute !important;
      top: 455px;
      background-color: transparent;
    }
  }
  .rightSection .headerTop .headerLeft ul.header_crruntActivityRow {
    flex-wrap: wrap;
    max-width: 774px;
    margin: 0 auto;
    justify-content: center;
  }

  .rightSection .headerTop .headerLeft ul.header_crruntActivityRow li {
    align-items: center;
    margin: 0 10px 14px;
    width: 46%;
  }
  .rightSection .headerTop .headerLeft ul.header_crruntActivityRow li strong {
    font-size: 46px;
    line-height: 76px;
  }
  .rightSection .headerTop .headerLeft ul.header_crruntActivityRow li span {
    font-size: 26px;
    font-weight: 700;
  }
  .mainBlock {
    flex-direction: column;
    height: auto;
  }
}
.for_desk {
  display: block;
  @media (max-width: 991px) {
    display: none;
  }
}
.for_mobile {
  display: none;
  @media (max-width: 991px) {
    display: block;
  }
}
.border_box {
  position: relative;
  padding-top: 12px !important;
  margin-bottom: 25px !important;
  border: 3px;
  border-style: solid;
  border-image-slice: 1;
  border-image-source: linear-gradient(to left, #ff0000, #2094fa);
  &:before {
    position: absolute;
    left: -10px;
    top: 50px;
    content: "";
    background: url("../../images/icon-frame-left.png") no-repeat center center /
      100% 100%;
    width: 10px;
    height: 180px;
  }
}

@media (max-width: 767px) {
  .ui.table.tableTransitionList {
    display: block;
    overflow-x: auto;
  }
}

.Commission {
  max-width: 500px;
  background: #141015 !important;
  border-radius: 10px;
  .header {
    border-radius: 10px 10px 0 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2) !important;
    background: transparent !important;
    font-size: 22px !important;
    color: $textColor !important;
    font-weight: 700 !important;
    display: flex !important;
    align-items: center;
    padding: 20px 45px !important;
    justify-content: space-between;
    .closebtn {
      width: 15px;
      height: 15px;
      position: relative;
      &::before {
        position: absolute;
        left: 0;
        top: 0;
        content: "";
        background: url(../../images/close_icon.png) no-repeat !important;
        width: 15px;
        height: 15px;
        background-size: 100% !important;
      }
    }
  }
  .content {
    background: transparent !important;
    padding: 50px 45px 55px 45px !important;
    .description {
      margin-left: 0 !important;
      margin-right: 0 !important;
      min-width: 100% !important;
    }
    .loginBox {
      display: flex;
      justify-content: space-between;
      padding: 0 10px;
      width: 100%;
      a {
        background: #1c171e;
        width: 171px;
        height: 165px;
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: 18px;
        font-weight: 700;
        color: $textColor;
        img {
          margin-bottom: 15px;
        }
      }
    }
    h3 {
      font-size: 52px;
      text-align: center;
      color: $textColor;
      font-weight: 700;
      margin-bottom: 0px;
      span {
        font-weight: 200;
      }
    }
    p {
      font-size: 19px;
      text-align: center;
      font-weight: 400;
      color: #b277e6;
      text-transform: uppercase;
    }
    form {
      margin-top: 45px;
      .field {
        margin-bottom: 30px;
        label {
          font-size: 16px;
          color: $textColor;
          font-weight: 400;
          margin-bottom: 8px;
        }
        .input {
          background: #1c171e !important;
          height: 55px;
          display: flex;
          padding-left: 10px;
          padding-right: 10px;
          align-items: center;
          border-radius: 30px;
          margin-bottom: 15px;
          input {
            background: transparent !important;
            color: $textColor;
            font-size: 14px;
            &::placeholder {
              color: $textColor;
              opacity: 1;
            }
          }
          .label {
            font-size: 15px;
            background: transparent !important;
            border: 0px !important;
            color: #b277e6;
          }
        }
      }
      button {
        background: #9669ed;
        width: 100%;
        font-size: 18px;
        color: $textColor;
        text-transform: uppercase;
        padding: 15px 0;
        display: block;
        border-radius: 25px;
        text-align: center;
        margin-top: 35px;
      }
    }
  }
}
.InfoBox {
  @media (max-width: 991px) {
    margin-top: 25px !important;
  }
  h3 {
    background: #000;
    color: $textColor;
    font-size: 21px;
    font-style: normal;
    margin-bottom: 0;
    font-weight: 600;
    padding: 19px 30px;
    border-radius: 15px 15px 0 0;
    @media (max-width: 1500px) {
      font-size: 18px;
    }
    @media (max-width: 1366px) {
      font-size: 15px;
      padding: 19px 12px;
    }
  }
  .InfoBoxIn {
    display: flex;
    background: $boxBg;
    align-items: center;
    justify-content: center;
    height: 228px;
    border-radius: 0 0 15px 15px;
    flex-direction: column;
    @media (max-width: 1366px) {
      height: 237px;
    }
    @media (max-width: 991px) {
      height: 170px;
    }
    .Info {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      h4 {
        color: $textColor;
        font-size: 45px;
        font-weight: 300;
        margin-bottom: 0;
        @media (max-width: 1500px) {
          font-size: 35px;
        }
        @media (max-width: 1366px) {
          font-size: 30px;
        }
      }
      p {
        font-size: 19px;
        color: $mainText;
        font-weight: 300;
        @media (max-width: 1366px) {
          font-size: 16px;
          text-align: center;
        }
      }
    }
    a {
      font-size: 18px;
      color: $textColor;
      font-weight: 700;
      background-image: $gradient;
      border: 0px;
      text-align: center;
      width: 80%;
      margin-top: 40px;
      border-radius: 28px;
      cursor: pointer;
      padding: 18px 25px;
      @media (max-width: 1250px) {
        padding: 10px 0px;
        margin-top: 15px;
      }
      @media (max-width: 991px) {
        width: 250px;
        padding: 10px 0px;
        margin-top: 15px;
      }

      &:hover {
        background: linear-gradient(to right, #1c8ee3, #41cff0);
      }
    }
  }
}
@media (max-width: 991px) {
  .InfoBoxWorking {
    margin-top: 0 !important;
    .row {
      padding-top: 0 !important;
    }
  }
}
.IncomeTable {
  .tabular {
    border: 0px !important;
    background: transparent !important;
    a {
      color: #fff !important;
      border: 0px !important;
      border-radius: 10px 10px 0 0px !important;
      &.active {
        background: linear-gradient(to right, #1c8ee3, #41cff0) !important;
      }
    }
  }
  .segment {
    padding: 0px !important;
    border: 0px !important;
    background: transparent !important;
    table {
      thead {
        tr {
          th {
            &:first-child {
              border-radius: 0px !important;
            }
          }
        }
      }
      tbody {
        tr {
          td {
            
            &.centeralign {
              padding: 20px 0 80px 0 !important;
              text-align: center !important;
            }
          }
        }
      }
    }
  }
}
