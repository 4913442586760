.form-input {
  padding: 30px;
  .fields {
    flex-direction: column !important;
  }
  .ui.fluid.input {
    margin-bottom: 10px;
  }

  .ui.form textarea {
    border: solid 1px #23aaff;
  }
  .ui.selection.dropdown {
    border: solid 1px #23aaff;
    height: 52px;
    display: flex;
    align-items: center;
  }
  .ui.form .field > .selection.dropdown > .dropdown.icon {
    float: right;
    top: 17px;
  }
  .description {
    width: 100%;
  }
  .flex {
    display: flex;
    justify-content: end;
  }
}
